import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { Home as HomeIcon } from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import SellIcon from '@mui/icons-material/Sell';
import { IconPlaneDeparture, IconBell } from '@tabler/icons-react';
import { useEffect } from 'react';

export default function MarketsSidebar() {
  const { pathname } = useLocation();

  const menuItems = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Announcements', icon: <IconBell />, path: '/markets/announcements', paths: ['/markets/announcements'] },
    { text: 'Markets', icon: <IconPlaneDeparture />, path: '/markets/list', paths: ['/markets/list', '/markets/add'] },
    {
      text: 'Categories',
      icon: <CategoryIcon />,
      path: '/markets/categories/list',
      paths: ['/markets/categories/list', '/markets/categories/list']
    },
    { text: 'Bookings', icon: <SellIcon />, path: '/markets/bookings/list', paths: ['/markets/bookings/list', '/markets/bookings/create'] }
  ];

  // useEffect(() => {
  //   const pathArray = location.pathname.split('/');
  //   const lastPathValue = pathArray[pathArray.length - 1];
  //   setValue(lastPathValue);
  //
  // }, [location]);

  return (
    <Box
      sx={{
        position: 'fixed',
        overflow: 'hidden',
        marginTop: 3,
        height: '50vh',
        width: 230,
        boxSizing: 'border-box',
        bgcolor: 'background.paper'
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            component={NavLink}
            to={item.path}
            key={index}
            style={{ padding: 16, backgroundColor: (item.paths ?? []).includes(pathname) ? '#f0f0f0' : '' }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
